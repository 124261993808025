.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #030015;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: white;
}

.App-body {
  background-color: #030015;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: white;

}

.Contact-form {
  font-size: calc(5px + 2vmin);
  padding:5px;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-size: calc(30px + 1vmin);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.App-link {
  color: #61dafb;
}

.App-body a {
  color: white;
  text-decoration: underline;
}
